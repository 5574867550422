import React, { useMemo, useState } from 'react'
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    LayoutRectangle,
} from 'react-native'
import { IDropDown, Item, IDropDownState } from './interface'
import { styles } from './styles'
import { OutsideClickHandler } from '..'

const DropDown = (props: IDropDown) => {
    const { 
        onSelect,             
        placeHolder,
        dropdownIcon,
        placeHolderContainerStyle,
        headerTextStyle,
        placeHolderLabel,
        placeHolderTextStyle,
        testID,
        listContainerStyle,
        textStyle,
        selectedListContainerStyle,
        zIndex,
        containerStyle,
        contentContainerStyle,
        options
    } = props
    const [isVisible, setIsVisible] = useState(props.initialVisibility || false)
    const [headerLayout, setHeaderLayout] = useState<LayoutRectangle>(undefined)
    const [value, setValue] = useState(null)

    const onPressPlaceHolder = () => {
        setIsVisible(!isVisible)
    }


    const onItemPress = (item: Item) => {
        setValue(item)
        setIsVisible(false)
        onSelect?.(item)
    }

    const renderDropDownHeader = useMemo(() => {
        return (
            <TouchableOpacity
                testID={`${testID}-headerButton`}
                onPress={onPressPlaceHolder}
                style={[styles.placeHolderContainer, placeHolderContainerStyle]}
                onLayout={(e) => {
                    setHeaderLayout(e.nativeEvent.layout)
                }}
            >
                {value?.image && (
                    <View style={styles.iconContainer}>{value.image}</View>
                )}
                { placeHolderLabel && 
                    <Text style={[styles.placeholderLabelTextStyle, placeHolderTextStyle]}>
                        {placeHolderLabel}
                    </Text>
                }
                <Text style={[styles.placeholderTextStyle, headerTextStyle]}>
                    {value?.name ? value?.name : placeHolder}
                </Text>
                <View style={styles.imageContainer}>
                    {dropdownIcon ? (
                        dropdownIcon
                    ) : (
                        <Image
                            resizeMode="contain"
                            style={[styles.image]}
                            source={{ uri: 'https://i.imgur.com/aXzX4Z7.png' }}
                        />
                    )}
                </View>
            </TouchableOpacity>
        )
    }, [placeHolderLabel, value?.name])

    const renderDropDownContent = useMemo(() => {
        return options.map((item: any, index: any) => {
            const isActive = item?.key == value?.key
            return (
                <TouchableOpacity
                    testID={`${testID}-content-${index}`}
                    activeOpacity={1}
                    delayPressOut={80}
                    onPress={() => onItemPress(item)}
                    style={[
                        styles.listContainer,
                        isActive ? { ...styles.selectedListContainer, ...selectedListContainerStyle as object } : listContainerStyle 
                    ]}
                    key={index}
                >
                    {item.image ? (
                        <View style={styles.iconContainer}>{item.image}</View>
                    ) : null}
                    <Text style={[styles.placeholderTextStyle, textStyle]}>
                        {item?.name}
                    </Text>
                </TouchableOpacity>
            )
        })
    }, [options, value, headerLayout])

    return (
                <View
                    testID={testID}
                    style={[
                        styles.container,
                        zIndex ? { zIndex } : {},
                        containerStyle,
                    ]}
                >
                    {renderDropDownHeader}
                    {(options.length > 0 && isVisible && headerLayout) ? (
                        <View
                            testID={`${testID}-dropDown`}
                            style={[styles.contentContainer, {top: headerLayout.height + headerLayout.y, left: headerLayout.x}, contentContainerStyle]}
                        >
                            <OutsideClickHandler  onPress={() => { 
                                setIsVisible(false)
                            }}>
                                {renderDropDownContent}
                            </OutsideClickHandler>
                        </View>
                    ) : null}
                    
                </View>
        )
    
}

export default DropDown
